<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >

    <!-- general tab -->
    <b-tab active>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('GENERAL') }}</span>
      </template>

      <account-setting-general
        v-if="user"
        :user-data="user"
        :save-or-update="saveOrUpdate"
      />
    </b-tab>
    <!--/ general tab -->

    <!-- change password tab -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('CHANGE_PASSWORD') }}</span>
      </template>

      <account-setting-password
        v-if="user"
        :user-data="user"
        :save-or-update="saveOrUpdate"
      />
    </b-tab>
    <!--/ change password tab -->
    <!-- notification -->
    <b-tab>

      <!-- title -->
      <template #title>
        <feather-icon
          icon="BellIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">{{ $t('NOTIFICATIONS') }}</span>
      </template>

      <account-setting-notification
        v-if="user"
        :notification-data="user"
        :save-or-update="saveOrUpdate"
      />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import { mapActions } from 'vuex'
import AccountSettingGeneral from './AccountSettingGeneral.vue'
import AccountSettingPassword from './AccountSettingPassword.vue'
import AccountSettingNotification from './AccountSettingNotification.vue'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountSettingNotification,
  },
  data() {
    return {
      options: {},
      user: {
        userID: '',
        firstname: '',
        lastname: '',
        nick: '',
        companyID: '',
        email: '',
        phonenumber: '',
        userRoleId: 0,
        about_me: '',
        send_daily_report: false,
        send_settime_report: false,
        is_disable: 0,
        language: '',
      },
    }
  },
  async created() {
    let id = 0
    if (router.currentRoute.params.id) {
      id = router.currentRoute.params.id
    } else {
      id = useJwt.getUser().id
    }
    if (id) {
      await this.fetchUser(id)
    }
  },
  methods: {
    ...mapActions('user', ['updateUser', 'fetchUserByID']),
    async fetchUser(id) {
      try {
        await this.fetchUserByID(id).then(response => {
          if (response) {
            const { data } = response.data
            this.user = data
          }
        })
      } catch (error) {
        this.showErrorMessage()
      }
    },
    saveOrUpdate(data) {
      if (data.userID) {
        this.updateUser(data).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.USER_UPDATED'))
          }
        }).catch(() => {
          this.showErrorMessage()
        })
      }
    },
  },
  setup() {
    const {
      successMessage, showErrorMessage,
    } = mixinAlert()
    return {
      successMessage,
      showErrorMessage,
    }
  },
}
</script>
